import { Button } from 'react-bootstrap'

import { Link, useNavigate, useParams } from 'react-router-dom'
import './index.css'
import { BsFillCartCheckFill } from 'react-icons/bs'
import { GrFormUpload } from 'react-icons/gr'
import { useAppStore } from '../AppStore'
import { useEffect, useState } from 'react'
import axios from 'axios'
import NavbarEvyapar from '../NavbarEvyapar'


const AddProductForm = () => {

    const dopen = useAppStore((state) => state.dopen)

    // const [arrayValues, setArrayValues] = useState([])



    // const [productName, setProductName] = useState()

    // const onProductNameChange = (e) => {
    //     setProductName(e.target.value)

    // }





    // const [productPrice, setProductPrice] = useState()

    // const onChangeProductPrice = (e) => {
    //     setProductPrice(e.target.value)

    // }

    // const [productDiscount, setProductDiscount] = useState()


    // const onChangeProductDiscount = (e) => {
    //     setProductDiscount(e.target.value)

    // }

    // const [date, setDate] = useState()


    // const onChangeProductDate = (e) => {
    //     setDate(e.target.value)

    // }


    // const [productImage, setProductImage] = useState()


    // const onChangeProductImg = (e) => {
    //     setProductImage(e.target.value)
    // }

    // const [productDescription, setProductDescription] = useState()

    // const onChangeproductDescription = (e) => {
    //     setProductDescription(e.target.value)
    // }

    const [emaill, setEmail] = useState()

    const onEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const [companyname, setCompanyName] = useState()

    const onCompanyNameChange = (e) => {
        setCompanyName(e.target.value)
    }

    const [location, setLocation] = useState()

    const onLocationChange = (e) => {
        setLocation(e.target.value)
    }

    const [mobileNo, setMobile] = useState()

    const onMobileNoChange = (e) => {
        setMobile(e.target.value)
    }


    const [pName, setPName] = useState()
    const [pPrice, setPPrice] = useState(45)
    const [validity, setValidity] = useState()
    const [pPhoto, setPPhoto] = useState()
    const [pAbout, setPAbout] = useState()
    const [discount, setDiscount] = useState(10)
    const [product, setProduct] = useState([])

    const onProductNameChange = (e) => {
        setPName(e.target.value)

    }

    const onChangeProductPrice = (e) => {
        setPPrice(e.target.value)
    }

    const onChangeProductDiscount = (e) => {
        setDiscount(e.target.value)

    }

    const onChangeProductDate = (e) => {
        setValidity(e.target.value)

    }

    const onChangeProductImg = (e) => {
        setPPhoto(e.target.files[0])
    }

    const onChangeproductDescription = (e) => {
        setPAbout(e.target.value)
    }


    const navigate = useNavigate()
    const addProduct = (e) => {

        alert(sessionStorage.getItem('name'))

        e.preventDefault()
        const email = sessionStorage.getItem('email')
        const formData = new FormData();
        formData.append('pName', pName)
        formData.append('pPrice', pPrice)
        formData.append('photo', pPhoto)
        formData.append('pAbout', pAbout);
        formData.append('validity', validity);
        formData.append('discount', discount);
        formData.append('email', email);

        formData.append('name', sessionStorage.getItem('name'))
        formData.append('mobile', sessionStorage.getItem('mobile' ))
        formData.append('company', sessionStorage.getItem('company'))
        formData.append('location', sessionStorage.getItem('location'))

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post("https://vyaparapi.emedha.in/product/post", formData, config)
            .then((response) => {
                alert(response.data);
                window.location.reload()
            }).catch((error) => {
            });
    }


    const getProductData = () => {
        axios.get("https://vyaparapi.emedha.in/product/get").then((resp) => {
            setProduct(resp.data)
        })
    }




    const [dis, setDis] = useState()

    useEffect(() => {
        getProductData()
        const dis = ((discount / 100) * pPrice)
        setDis(dis)
    }, [])

    const disableDate = () => {
        var today, dd, mm, yyyy;
        today = new Date();
        dd = today.getDate() + 1;
        mm = today.getMonth() + 1
        yyyy = today.getFullYear()
        return yyyy + "-" + mm + "-" + dd;
    }





    return (
        <>
            {/* <NavbarEvyapar/> */}

            <div className='container' style={{ marginTop: 140 }} >
                <div className='row add-product-form-bg-container'>
                    <div className='col-lg-8 d-flex flex-column jusify-content-center'>
                        <form className='Add-product-form-container' onSubmit={addProduct}>
                            <h1 className='add-product-heading text-primary'>Add Product <BsFillCartCheckFill style={{ color: 'black', marginLeft: 10 }} /></h1>
                            <input type='text' id='productName' className='add-product-input' placeholder='Product name' onChange={onProductNameChange} />
                            <input type='text' id='productPrice' className='add-product-input' placeholder='Product price' onChange={onChangeProductPrice} />
                            <input type='text' id='productDiscount' className='add-product-input' placeholder='Product Discount' onChange={onChangeProductDiscount} />
                            <input type='date' id='date' className='add-product-input' onChange={onChangeProductDate} min={disableDate()} />
                            <div className='add-product-file'><input type="file" id="myFile" name="filename" onChange={onChangeProductImg}></input></div>
                            <textarea rows='4' cols='40' placeholder='About Product' className='mt-4' style={{ marginLeft: 80 }} onChange={onChangeproductDescription}></textarea>
                            <div className='text-center'><Button className='upload-btn' type='submit' onClick={getProductData}>Upload Product <GrFormUpload style={{ color: 'white', marginLeft: 6 }} /></Button></div>
                        </form>
                    </div>
                </div>

            </div>
            {/* {arrayValues.map((eachObj) => {
                <AddMyProduct
                    // key={eachObj.id}
                    ProductArray={eachObj}
                />

            })} */}
        </>
    )
}

export default AddProductForm