import { Component } from 'react'

import { Container, Card, Row, Col, Form, Modal, Nav, Button } from 'react-bootstrap'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'
import './index.css'
import RegisterPageSlider from '../RegisterPageSlider'
import RegisterForm from '../RegisterForm'

class LoginPage extends Component {

    state = { isOpen: false, guestEmail: "", lgShow: false, setLgShow: false, isActiveBtn: false, username: '', loginEmail: '', loginPass: '', showPasswordErr: false, showEmailErr: false, isFormSubmit: false }


    // login=()=>{
    //     axios.post('https://vyaparapi.emedha.in/login',{
    //        loginEmail,
    //         loginPass
    //     })
    // }

    onChangeUsername = event => {
        this.setState({ username: event.target.value })
    }

    onChangeEmail = event => {
        this.setState({ loginEmail: event.target.value })
    }

    onChangePassword = event => {
        this.setState({ loginPass: event.target.value })
    }

    validEmail = () => {
        const { loginEmail } = this.state
        return loginEmail !== ""
    }

    validPassword = () => {
        const { loginPass } = this.state
        return loginPass !== ""
    }

    onBlurPassword = () => {
        const validPassword = this.validPassword()
        this.setState({ showPasswordErr: !validPassword })
    }

    onBlurEmail = () => {
        const validEmail = this.validEmail()
        this.setState({ showEmailErr: !validEmail })
    }

    onChange = () => {
        this.setState({ isActiveBtn: true })
    }

    onChangeGuestEmail = event => {
        this.setState({ guestEmail: event.target.value })


    }

    onSubmitForm = (event) => {
        event.preventDefault()
        const validEmail = this.validEmail()
        const validPassword = this.validPassword()

        const email = {
            loginEmail: this.state.loginEmail
        };

        const password = {
            loginPass: this.state.loginPass
        }

        const loginPass = password.loginPass
        const loginEmail = email.loginEmail

        axios.post(`https://vyaparapi.emedha.in/login`, { loginEmail, loginPass })

            .then(res => {
                if (res.data.statusCode === 200) {

                    alert(res.data.message)

                    window.location.href = '/user-dashboard';
                    
                    sessionStorage.setItem('key', loginEmail)
                    sessionStorage.setItem(`email`, loginEmail)
                    sessionStorage.setItem('name',res.data.name)
                    sessionStorage.setItem('company',res.data.company)
                    sessionStorage.setItem('mobile',res.data.mobile)
                    sessionStorage.setItem('location',res.data.location)
                    sessionStorage.setItem('userId',res.data.userId)
                } else {
                    alert(res.data) 
                }
            })


        if (validEmail && validPassword) {
            this.setState({ isFormSubmit: true })
            sessionStorage.setItem("authenticated", 'login');
        } else {
            this.setState({
                showEmailErr: !validEmail,
                showPasswordErr: !validPassword,
                isFormSubmit: false

            })

        }

    }

    openEmailPop = () => {
        this.setState({ setLgShow: true, lgShow: true })
    }

    closePop = () => {
        this.setState({ setLgShow: false, lgShow: false })
    }

    onOpenRegisterPage = () => {
        this.setState({ isOpen: true })
    }



    render() {
        const { isOpen, lgShow, setLgShow, guestEmail, isActiveBtn, username, loginEmail, loginPass, showPasswordErr, showEmailErr, isFormSubmit } = this.state
        console.log(username)

        return (
            <>
                {isOpen ? <RegisterForm /> :

                    (<div className='container-fluid login-container'>
                        <div className='row login-card' style={{ backgroundColor: 'white' }}>
                            <div className='col-lg-5 text-center mx-auto login-logo'>
                                {/* <img src="https://play-lh.googleusercontent.com/NZaFTTPY51Zv7SvfhssvX26hlNfJB1O8HLjdkPg1a1Zepgi5ORFEaN1ZohgFbB1-lp41" className='login-logo' style={{ borderRadius: 8, width: 350 }} alt='logo'></img> */}
                                <RegisterPageSlider />
                                <br />

                                <h5 className="login-heading" style={{ fontWeight: 800, color: 'black' }}>Welcome To <span style={{ color: 'rgb(13, 10, 70)', fontSize: 20 }}>e-Vyapar</span> <span style={{ color: '#d9370d', fontSize: 24 }}>Exchange</span></h5>
                            </div>

                            <div className='col-lg-5 mx-auto m-2 border-0 login-form-card' style={{ border: '1px solid black', borderRadius: 5 }}>
                                <Card className='m-2 '>
                                    <Card.Header style={{ backgroundColor: 'white' }} >
                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                            <h1 style={{ fontSize: 18, padding: 10, color: 'black' }}>Please Login</h1>
                                            <img src='https://play-lh.googleusercontent.com/NZaFTTPY51Zv7SvfhssvX26hlNfJB1O8HLjdkPg1a1Zepgi5ORFEaN1ZohgFbB1-lp41' style={{ width: 70 }} alt='logo' />

                                        </div>

                                    </Card.Header>

                                    <Form onSubmit={this.onSubmitForm} className='p-3'>
                                        <Form.Label style={{ marginTop: 10, marginLeft: 2, color: 'black', fontWeight: '600' }}>Email Address</Form.Label>
                                        <Form.Control type='text' id='loginEmail' placeholder="Enter Email" value={loginEmail} onChange={this.onChangeEmail} onBlur={this.onBlurEmail} className='input-box' />
                                        {showEmailErr && <p style={{ color: 'red', marginBottom: 0 }}>Required*</p>}
                                        <Form.Label style={{ marginTop: 10, marginLeft: 2, color: 'black', fontWeight: '600' }}>Password</Form.Label>
                                        <Form.Control type='password' id='loginPass' placeholder="Enter Password" value={loginPass} onChange={this.onChangePassword} onBlur={this.onBlurPassword} className='input-box' />
                                        {showPasswordErr && <p style={{ color: 'red', marginBottom: 0 }}>Required*</p>}

                                        <div className='text-center mt-5 g-recaptcha'>

                                            <ReCAPTCHA
                                                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                                                onChange={this.onChange}
                                            />
                                        </div>

                                        {isActiveBtn ? <button className='log-button' style={{ backgroundColor: 'rgb(13, 10, 70)', borderRadius: 5 }}>Login</button> : <button style={{ backgroundColor: 'transparent', borderRadius: 5, width: 150, marginTop: 30, height: 38 }} disabled>Login</button>}
                                        <div className='d-flex flex-column'>
                                            <a href="/email" style={{ color: 'red', marginTop: 10, fontSize: 18 }}>Forgot Password</a>
                                            <div className='d-flex flex-row align-items-center justify-content-between'>
                                                <button className='log-button create-button' style={{ marginTop: 20, borderRadius: 5, backgroundColor: '#f8d50d', borderColor: 'rgb(13,10,70)', fontWeight: '600' }} onClick={this.onOpenRegisterPage}>Create New Account</button>
                                                <Nav style={{ fontWeight: 'bold', marginRight: 15 }} onClick={this.openEmailPop}><Button style={{ backgroundColor: '#062d59', width: 120, marginLeft: 10, height: 35 }}>Guest Login </Button> </Nav>
                                            </div>
                                          
                                        </div>
                                    </Form>
                                </Card>
                            </div>
                        </div>
                    </div>)}
                <Modal
                    size="md"
                    show={lgShow}
                    onHide={this.closePop}
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered
                    backgroundColor='#e3010f'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Guest Login
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='container'>

                            <div className='row email-form-container'>
                                <div className='col-lg-12 mx-auto m-2 border-0 ' style={{ border: '1px solid black', borderRadius: 5 }}>
                                    <Card className='m-2 otp-card'>
                                        <Card.Header style={{ backgroundColor: 'rgb(251, 253, 255)' }} >
                                            <div className='d-flex flex-row justify-content-between align-items-center'>
                                                <h1 style={{ fontSize: 18, padding: 10, color: 'black' }}>Email  Verification</h1>
                                                <img src='https://play-lh.googleusercontent.com/NZaFTTPY51Zv7SvfhssvX26hlNfJB1O8HLjdkPg1a1Zepgi5ORFEaN1ZohgFbB1-lp41' style={{ width: 60 }} alt='logo' />

                                            </div>

                                        </Card.Header>

                                        <Form className='p-3' style={{ backgroundColor: 'lightcyan' }}>
                                            <Form.Label style={{ marginTop: 10, marginLeft: 2, color: 'black', fontWeight: '600' }}>Enter Your Email</Form.Label>
                                            <Form.Control type='email' id='email' className='input-box' onChange={this.onChangeGuestEmail} value={guestEmail} />

                                            <button className='verify-otp-button' style={{ backgroundColor: 'rgb(13, 10, 70)', marginTop: 20, borderRadius: 5 }}>Submit</button>

                                        </Form>
                                    </Card>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>



            </>
        )
    }
}

export default LoginPage



