import { MdStarRate } from 'react-icons/md'
import './index.css'
import { BsBagCheckFill, BsFillCartCheckFill } from 'react-icons/bs'
import { useState } from 'react'


// const StoreProductDetails=()=>{

//     const [isOpenImg1,setImg1]=useState(false)

//     const onOpenImg1=()=>{
//         setImg1(true)
//         setImg2(false)
//         setImg3(false)
//     }

//     const [isOpenImg2,setImg2]=useState(false)


//     return(
//         <div className='container-fluid' >
//             <div className='row detail-view-container'>
//                 <div className='col-lg-5'>
//                     {/* <div className='small-images-cards'>
//                     <img onClick={onOpenImg1} className='small-img' src='https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/q/h/8/iphone-13-mlpk3hn-a-apple-original-imag6vpywdtyhbkr.jpeg?q=70'/>
//                     <img onClick={onOpenImg2} className='small-img' src='https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/t/e/e/iphone-13-mlpk3hn-a-apple-original-imag6vpyggrjkms3.jpeg?q=70'/>
//                     <img onClick={onOpenImg3} className='small-img' src='https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/h/e/g/-original-imagh7g8k7ttcdkc.jpeg?q=70'/>
//                     <img onClick={onOpenImg4} className='small-img' src='https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/w/k/h/-original-imagh2gwxu57j4fn.jpeg?q=70'/>
//                     </div> */}
//                    <img width='100%' className='store-product-detail-img' src='https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/q/h/8/iphone-13-mlpk3hn-a-apple-original-imag6vpywdtyhbkr.jpeg?q=70'/>
//                     {/* {isOpenImg2?<img className='store-product-detail-img' src='https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/q/h/8/iphone-13-mlpk3hn-a-apple-original-imag6vpywdtyhbkr.jpeg?q=70'/>:<img className='store-product-detail-img' src='https://img.freepik.com/free-vector/realistic-display-smartphone-with-different-apps_52683-30241.jpg?w=740&t=st=1682333695~exp=1682334295~hmac=398b80328b2d2c7e8e9e0d97beb4778bb2afd91f29dfbb9c72eb4cf88d5b83f5'/>}
//                     {isOpenImg3?<img className='store-product-detail-img' src='https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/h/e/g/-original-imagh7g8k7ttcdkc.jpeg?q=70'/>:<img className='store-product-detail-img' src='https://img.freepik.com/free-vector/realistic-display-smartphone-with-different-apps_52683-30241.jpg?w=740&t=st=1682333695~exp=1682334295~hmac=398b80328b2d2c7e8e9e0d97beb4778bb2afd91f29dfbb9c72eb4cf88d5b83f5'/>} */}
//                  </div>
//                  <div className='col-lg-5 detail-content-card' style={{backgroundColor:'azure'}}>
//                     <h1 className='product-details-name'>Mobile</h1>

//                     <p><MdStarRate style={{marginRight:5}}/><MdStarRate style={{marginRight:5}}/><MdStarRate style={{marginRight:5}}/><MdStarRate style={{marginRight:5}}/> Good +</p>
//                     <p className='product-details-cost'>Rs 21000/- <span className='product-detail-orginal-cost'>30000</span><span className='product-detail-discount'>20% off</span></p>
//                     <button className='product-details-self-btn'><BsFillCartCheckFill style={{marginRight:8}}/>Self Referral</button>
//                     <button className='product-details-customer-btn'><BsBagCheckFill style={{marginRight:8}}/>Customer Referral</button>
//                     <p className='product-details-validty'>Offer Validity: 28/03/2023</p>
//                     <p className='product-details-description'><span className='product-details-description-head'>Description</span>: Treat yourself to the POCO C50, which has a plethora of innovative capabilities, and take advantage of a seamless smartphone experience. With its 16.56 cm (6.52) broad display and 120 Hz touch sampling rate, this smartphone makes it simple to enjoy content and elevate your visual experience. Additionally, The POCO C50 enhances the experience in terms of shooting great photographs with an 8 MP main camera with a depth sensor. You can also capture captivating selfies with this phone's 5 MP front camera to increase your social media profile.</p>
//                  </div>
//             </div>
//         </div>
//     )
// }

// export default StoreProductDetails





import { Component } from 'react'

import ThumbnailItem from '../ThumbnailItem'

import './index.css'
import NavbarEvyapar from '../NavbarEvyapar'

const imagesList = [
    {
        id: 0,
        imageUrl:
            'https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/q/h/8/iphone-13-mlpk3hn-a-apple-original-imag6vpywdtyhbkr.jpeg?q=70',
        thumbnailUrl:
            'https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/q/h/8/iphone-13-mlpk3hn-a-apple-original-imag6vpywdtyhbkr.jpeg?q=70',
        imageAltText: 'mobile image1',
        thumbnailAltText: 'mobile image1 thumbnail',
    },
    {
        id: 1,
        imageUrl: 'https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/t/e/e/iphone-13-mlpk3hn-a-apple-original-imag6vpyggrjkms3.jpeg?q=70',
        thumbnailUrl:
            'https://rukminim1.flixcart.com/image/416/416/ktketu80/mobile/t/e/e/iphone-13-mlpk3hn-a-apple-original-imag6vpyggrjkms3.jpeg?q=70',
        imageAltText: 'mobile image2',
        thumbnailAltText: 'mobile image2 thumbnail',
    },
    {
        id: 2,
        imageUrl:
            'https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/h/e/g/-original-imagh7g8k7ttcdkc.jpeg?q=70',
        thumbnailUrl:
            'https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/h/e/g/-original-imagh7g8k7ttcdkc.jpeg?q=70',
        imageAltText: 'mobile image3',
        thumbnailAltText: 'mobile image3 thumbnail',
    },
    {
        id: 3,
        imageUrl:
            'https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/w/k/h/-original-imagh2gwxu57j4fn.jpeg?q=70',
        thumbnailUrl:
            'https://rukminim1.flixcart.com/image/416/416/xif0q/mobile/w/k/h/-original-imagh2gwxu57j4fn.jpeg?q=70',
        imageAltText: 'mobile image4',
        thumbnailAltText: 'mobile image4 thumbnail',
    },

]

// Write your code here.
class StoreProductDetails extends Component {
    state = {
        imgUrl: imagesList[0].imageUrl,
        imgAltText: imagesList[0].imageAltText,
    }

    onClickItem = (imageUrl, imageAltText) => {
        this.setState({ imgUrl: imageUrl, imgAltText: imageAltText })
    }

    render() {
        const { imgUrl, imgAltText } = this.state
        const checkList = imagesList.filter(
            eachValue => eachValue.imageUrl === imgUrl,
        )
        const { id } = checkList[0]
        return (
            <>
                {/* <NavbarEvyapar /> */}
                <div className='container-fluid' >
                    <div className='row detail-view-container'>
                        <div className='col-lg-5 thumbnail-image-container'>
                            <div className="small-images-cards">
                                {imagesList.map(eachObject => (
                                    <ThumbnailItem
                                        key={eachObject.id}
                                        imagesArray={eachObject}
                                        onClickItem={this.onClickItem}
                                        id={id}
                                    />
                                ))}
                            </div>
                            <img src={imgUrl} width='100%' className='store-product-detail-img' alt={imgAltText} />
                        </div>
                        <div className='col-lg-5 detail-content-card' style={{ backgroundColor: 'azure' }}>
                            <h1 className='product-details-name'>Mobile</h1>
                            <p><MdStarRate style={{ marginRight: 5 }} /><MdStarRate style={{ marginRight: 5 }} /><MdStarRate style={{ marginRight: 5 }} /><MdStarRate style={{ marginRight: 5 }} /> Good +</p>
                            <p className='product-details-cost'>Rs 21000/- <span className='product-detail-orginal-cost'>30000</span><span className='product-detail-discount'>20% off</span></p>
                            <button className='product-details-self-btn'><BsFillCartCheckFill style={{ marginRight: 8 }} />Self Referral</button>
                            <button className='product-details-customer-btn'><BsBagCheckFill style={{ marginRight: 8 }} />Customer Referral</button>
                            <p className='product-details-validty'>Offer Validity: 28/03/2023</p>                   <p className='product-details-description'><span className='product-details-description-head'>Description</span>: Treat yourself to the POCO C50, which has a plethora of innovative capabilities, and take advantage of a seamless smartphone experience. With its 16.56 cm (6.52) broad display and 120 Hz touch sampling rate, this smartphone makes it simple to enjoy content and elevate your visual experience. Additionally, The POCO C50 enhances the experience in terms of shooting great photographs with an 8 MP main camera with a depth sensor. You can also capture captivating selfies with this phone's 5 MP front camera to increase your social media profile.</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default StoreProductDetails