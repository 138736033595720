import { MdStarRate } from 'react-icons/md'
import './index.css'
import { BsBagCheckFill, BsFillCartCheckFill } from 'react-icons/bs'
import { Component } from 'react'
import ThumbnailItem from '../ThumbnailItem'
import './index.css'

const imagesList = [
    {
        id: 0,
        imageUrl:
            `https://vyaparapi.emedha.in/images/image-1679314242975.mobile.png`,
        thumbnailUrl:
            'https://vyaparapi.emedha.in/images/image-1679314242975.mobile.png',
        imageAltText: 'watch image1',
        thumbnailAltText: 'watch image1 thumbnail',
    },
    {
        id: 1,
        imageUrl: 'https://rukminim1.flixcart.com/image/416/416/l3ahpjk0/smartwatch/d/2/c/-original-imagegyhhnyrzhxg.jpeg?q=70',
        thumbnailUrl:
            'https://rukminim1.flixcart.com/image/416/416/l3ahpjk0/smartwatch/d/2/c/-original-imagegyhhnyrzhxg.jpeg?q=70',
        imageAltText: 'watch image2',
        thumbnailAltText: 'watch image2 thumbnail',
    },
    {
        id: 2,
        imageUrl:
            'https://rukminim1.flixcart.com/image/416/416/l3ahpjk0/smartwatch/n/h/u/-original-imagegyh8pcbphez.jpeg?q=70',
        thumbnailUrl:
            'https://rukminim1.flixcart.com/image/416/416/l3ahpjk0/smartwatch/n/h/u/-original-imagegyh8pcbphez.jpeg?q=70',
        imageAltText: 'watch image3',
        thumbnailAltText: 'watch image3 thumbnail',
    },
    {
        id: 3,
        imageUrl:
            'https://rukminim1.flixcart.com/image/416/416/l3ahpjk0/smartwatch/t/j/r/-original-imagegyhzzxhqcec.jpeg?q=70',
        thumbnailUrl:
            'https://rukminim1.flixcart.com/image/416/416/l3ahpjk0/smartwatch/t/j/r/-original-imagegyhzzxhqcec.jpeg?q=70',
        imageAltText: 'watch image4',
        thumbnailAltText: 'watch image4 thumbnail',
    },

]

class AllProductsDetailView extends Component {

    state = {
        imgUrl: imagesList[0].imageUrl,
        imgAltText: imagesList[0].imageAltText,
    }



    onClickItem = (imageUrl, imageAltText) => {
        this.setState({ imgUrl: imageUrl, imgAltText: imageAltText })
    }

    render() {
        const { imgUrl, imgAltText } = this.state
        const { productData } = this.props


        const checkList = imagesList.filter(
            eachValue => eachValue.imageUrl === imgUrl,
        )
        const { id } = checkList[0]
        return (
            <>
                {/* <NavbarEvyapar /> */}
                <div className='container-fluid' >
                    <div className='row detail-view-container shadow p-2'>
                        <div className='col-lg-5 thumbnail-image-container'>
                            <div className="small-images-cards">
                                {imagesList.map(eachObject => (
                                    <ThumbnailItem
                                        key={eachObject.id}
                                        imagesArray={eachObject}
                                        onClickItem={this.onClickItem}
                                        id={id}
                                    />
                                ))}
                            </div>
                            <img src={`https://vyaparapi.emedha.in/images/${productData.imageUrl}`} width='65%'  alt={imgAltText} />
                        </div>
                        <div className='col-lg-5 detail-content-card' style={{ backgroundColor: 'azure' }}>
                            <h1 className='product-details-name'>{productData.title}</h1>
                            <p><MdStarRate style={{ marginRight: 5 }} /><MdStarRate style={{ marginRight: 5 }} /><MdStarRate style={{ marginRight: 5 }} /><MdStarRate style={{ marginRight: 5 }} /> Good +</p>
                            <p className='product-details-cost'>Price: {productData.price - productData.discount / 100 * productData.price} <span className='product-detail-orginal-cost'>{productData.price}</span><span className='product-detail-discount'>{productData.discount} %off</span></p>
                            <button className='product-details-self-btn'><BsFillCartCheckFill style={{ marginRight: 8 }} />Self Referral</button>
                            <button className='product-details-customer-btn'><BsBagCheckFill style={{ marginRight: 8 }} />Customer Referral</button>
                            <p className='product-details-validty'>Offer Validity: {productData.validity}</p>
                            <p className='product-details-description'><span className='product-details-description-head'>Description</span>: {productData.description}</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default AllProductsDetailView