import '../node_modules/bootstrap/dist/css/bootstrap.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import AdminLoginPage from './components/AdminLoginPage';
import AdminStore from './components/AdminStore';
import CostomerDashboard from './components/AdminDashboard/CostomerDashboard';




const App = () => {



   return (

      <>

         <BrowserRouter>
            <Routes>
               <Route path="/" element={<LoginPage></LoginPage>}></Route>
               <Route path='/user-dashboard' element = {<Dashboard></Dashboard>} ></Route>
               <Route path='/admin-login' element = {<AdminLoginPage></AdminLoginPage>} ></Route>
               <Route path='/admin-store' element = {<CostomerDashboard></CostomerDashboard>} ></Route>
            </Routes>
         </BrowserRouter>

      </>
   )
}

export default App;
