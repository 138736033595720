import { Button } from 'react-bootstrap'
import { CgDetailsMore } from 'react-icons/cg'
import { BsFilterRight } from 'react-icons/bs'
import FiltersGroup from '../FiltersGroup'
import './index.css'
import { Component } from 'react'
import AllProductsDetailView from '../AllProductsDetailView'
import axios from 'axios'
import NavbarEvyapar from '../NavbarEvyapar'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { FaWindowClose } from 'react-icons/fa'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'auto',
    color: theme.palette.text.secondary,
}));


const categoryOptions = [
    {
        name: 'All Products',
        categoryId: '0'
    },
    {
        name: 'Clothing',
        categoryId: '1',
    },
    {
        name: 'Electronics',
        categoryId: '2',
    },
    {
        name: 'Home&Furniture',
        categoryId: '3',
    },
    {
        name: 'Grocery',
        categoryId: '4',
    },
    {
        name: 'Toys',
        categoryId: '5',
    },
]

const ratingsList = [
    {
        ratingId: '4',
        imageUrl:
            'https://assets.ccbp.in/frontend/react-js/rating-four-stars-img.png',
    },
    {
        ratingId: '3',
        imageUrl:
            'https://assets.ccbp.in/frontend/react-js/rating-three-stars-img.png',
    },
    {
        ratingId: '2',
        imageUrl:
            'https://assets.ccbp.in/frontend/react-js/rating-two-stars-img.png',
    },
    {
        ratingId: '1',
        imageUrl:
            'https://assets.ccbp.in/frontend/react-js/rating-one-star-img.png',
    },
]

class AllProducts extends Component {




    state = {
        // activeOptionId: sortbyOptions[0].optionId,
        productsList: [],
        isOpenProductsDetail: false,
        activeCategoryId: categoryOptions[0].categoryId,
        searchInput: '',
        activeRatingId: '',
        searchList: []
    }


    componentDidMount() {
        this.getAllProducts()

    }

    getAllProducts = async () => {

        const apiUrl = `https://vyaparapi.emedha.in/all-product/get`
        const options = {

            method: 'GET',
        }

        const response = await fetch(apiUrl, options)
        if (response.ok) {
            const fetchedData = await response.json()
            const updatedData = fetchedData.map(product => ({
                title: product.pName,
                description: product.discription,
                price: product.pPrice,
                id: product.id,
                imageUrl: product.pPhoto,
                location: product.location,
                validity: product.validity,
                discount: product.discount,
            }))
            this.setState({
                productsList: updatedData,


            })


        }
    }


    openDetailViewOfProduct = () => {
        this.setState({ isOpenProductsDetail: true })
    }





    clearFilters = () => {
        this.setState(
            {
                searchInput: '',
                activeCategoryId: '',
                activeRatingId: '',
            }

        )
    }



    changeRating = activeRatingId => {
        this.setState({ activeRatingId })
    }

    changeCategory = activeCategoryId => {
        this.setState({ activeCategoryId })
    }


    changeSearchInput = searchInput => {
        this.setState({ searchInput })
        this.searchProduct(searchInput)
    }



    searchProduct = (product) => {
        axios.post('https://vyaparapi.emedha.in/search-product', {
            product
        }).then((res) => {
            this.setState({ productsList: res.data })
            this.setState({ searchList: res.data })
        })
    }




    render() {

        const { searchList, activeCategoryId, searchInput, activeRatingId, isOpenProductsDetail, productsList } = this.state

        console.log(productsList)

        return (


            <>

                {isOpenProductsDetail ? <>{productsList.map(product => (<AllProductsDetailView productData={product} key={product.id} />))}</> :
                    <div>
                        <div className='all-products-section'>
                            <FiltersGroup
                                searchInput={searchInput}
                                enterSearchInput={this.enterSearchInput}
                                changeSearchInput={this.changeSearchInput}
                                categoryOptions={categoryOptions}
                                activeCategoryId={activeCategoryId}
                                changeCategory={this.changeCategory}
                                ratingsList={ratingsList}
                                changeRating={this.changeRating}
                            />
                            <div style={{ width: '83%', position: 'absolute', right: 0, top: 0, bottom: 5 }}>
                                <Box sx={{ flexGrow: 1 }}>

                                    <Grid container sx={{ paddingLeft: 4, paddingRight: 2, marginTop: 15, marginBottom: 5, paddingBottom: 4 }} spacing={2}>

                                        {
                                            productsList.map((data) => {
                                                return (
                                                    <Grid item lg={3} xs={6}>
                                                        <Item className='shadow' style={{ height: 483, position: 'relative',borderRadius:'0.6rem' }}>
                                                            <Card elevation={0} sx={{ maxWidth: '100%', justifyContent: 'center', alignItems: 'center',borderRadius:'0.6rem'}}>
                                                                <center>
                                                                    <CardActionArea>

                                                                        <CardMedia
                                                                            component="img"
                                                                            image={`https://vyaparapi.emedha.in/images/${data.imageUrl}`}
                                                                            alt="green iguana"
                                                                            style={{ width: '73%',borderRadius:'0.6rem' }}

                                                                        />
                                                                    </CardActionArea>
                                                                </center>
                                                            </Card>
                                                            <CardContent>
                                                                <Box style={{ height: '10rem', position: 'absolute', left: 0, right: 0, bottom: 0, backgroundColor: 'white', paddingLeft: 15 }}>
                                                                    <h1 style={{ fontSize: 20, paddingTop: 8, color: 'red', fontWeight: 'bold' }}><span style={{ fontSize: 18 }}>Product: </span>{data.title}</h1>
                                                                    <Typography style={{ fontSize: 15 }}><span style={{ fontSize: 15 }}>Validity: </span>{data.validity}</Typography>
                                                                    <Typography style={{ fontSize: 15 }}>Price: {data.price - data.discount / 100 * data.price} <span className='product-detail-orginal-cost' style={{ fontSize: 18 }}>{data.price}</span></Typography>
                                                                    <Typography style={{ fontSize: 15 }}> <span style={{ fontSize: 15 }}>Discount: </span>{data.discount}</Typography>
                                                                    <button onClick={this.openDetailViewOfProduct} style={{ borderRadius: '0.2rem', fontSize: 15, border: 'none', backgroundColor: 'red', color: 'white', height: '2.2rem', width: '7.5rem', marginTop: 4 }}>More Details</button>
                                                                </Box>
                                                            </CardContent>
                                                        </Item>
                                                    </Grid>
                                                )
                                            })
                                        }

                                    </Grid>
                                </Box>
                            </div>
                        </div>
                    </div>

                }

            </>
        )
    }
}

export default AllProducts


