import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'



const RegisterPageSlider = () => {

    const settings = {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,

    }

    return (

        < Slider {...settings}>




            <div>
                <img className='login-page-slider-img' src='https://img.freepik.com/free-vector/online-store-isometric-style_23-2147632454.jpg?w=740&t=st=1681990848~exp=1681991448~hmac=e2dde72b3578aff6536648fa220f18e66f1f3830b576178a59b45a8c5e883c74' />

            </div>


            <div>
                <img className='login-page-slider-img' src='https://img.freepik.com/free-vector/flat-hand-drawn-flea-market-illustration-with-people_23-2148829660.jpg?w=996&t=st=1682083349~exp=1682083949~hmac=cc521a297c1196afae1a8b194296fa981a80dc50e3adf935169bd015fe485efd' />


            </div>

            <div>
                <img className='login-page-slider-img' src='https://img.freepik.com/free-vector/online-store-isometric-style_23-2147632454.jpg?w=740&t=st=1681990848~exp=1681991448~hmac=e2dde72b3578aff6536648fa220f18e66f1f3830b576178a59b45a8c5e883c74' />


            </div>

            <div>
                <img className='login-page-slider-img' src='https://img.freepik.com/free-vector/online-store-isometric-style_23-2147632454.jpg?w=740&t=st=1681990848~exp=1681991448~hmac=e2dde72b3578aff6536648fa220f18e66f1f3830b576178a59b45a8c5e883c74' />

            </div>


        </Slider >
    )
}

export default RegisterPageSlider