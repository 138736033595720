import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FaTimes, FaExclamationTriangle, FaLeaf } from 'react-icons/fa';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FaEdit, FaWindowClose } from 'react-icons/fa';
import Swal from "sweetalert2";

const GstKyc = () => {

    const [wait7, setWait7] = useState(false)

    const GstUpdate = () => {
        Swal.fire({
            title: "Successful..!",
            text: "Your business data successfully updated...!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {
                setOpen(false)
                setWait7(false)
            }
        });
    }

    const NoGstAl = () => {
        Swal.fire({
            title: "No GST..!",
            cancelButtonText: 'Cancel',
            text: "Add business details and improve you profile...!",
            showCancelButton: 'Cancel',
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {
                setOpen(false)
                setIsUpdateGst(false)
                kycStatus()
            }
        });
    }

    const GstError = (err) => {
        Swal.fire({
            title: "Error..!",
            text: err,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
        }).then((result) => {
            if (result.value) {
                setWait7(false)
            } else {
                setOpen(false)
                setWait7(false)
            }
        });
    }



    const [GstValidated, setGstValidated] = useState(false);
    const [GstValidated1, setGstValidated1] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            gstPanValidation()
        }
        setGstValidated(true);
    };

    const handleSubmitGst1 = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            gstPanValidation()
        }
        setGstValidated1(true);
    };




    const [error, setError] = useState(false)
    const [isGst, setIsGst] = useState(false)
    const [GST, setGst] = useState('')
    const [pan, setPan] = useState('')
    const [isAadhar, setIsAadhar] = useState(false)
    const [isGstOpt, setIsGstOpt] = useState(true)
    const [isOtp, setIsOtp] = useState(false)
    const [isForm, setIsForm] = useState(false)

    const [firm, setFirm] = useState('')
    const [business, setBusiness] = useState('')
    const [isProfileSubMenu, setIsprofileSubmenu] = useState(false)
    const [isBank, setIsBank] = useState(false)


    const [newGst, setNewGst] = useState()
    const [newGst1, setNewGst1] = useState()
    const [newGst2, setNewGst2] = useState()
    const [newPan, setNewPan] = useState()

    const gstPanValidation = () => {
        const CCEncoded = GST.substring(GST.length + -3, 2);
        const CCEncoded1 = GST.substring(GST.length + 0, 12);
        const CCEncoded2 = GST.substring(GST.length + -13, 0);

        if (CCEncoded === pan) {
            customerBusinessDatAdd()
        } else {
            setNewGst(CCEncoded)
            setNewGst1(CCEncoded1)
            setNewGst2(CCEncoded2)
            setNewPan(pan)
            setError('yes')
        }

    }



    const [gstImage, setGstImage] = useState()

    const customerBusinessDatAdd = () => {
        setWait7(true)
        const client_id = sessionStorage.getItem('client_id')

        const formData = new FormData();
        formData.append('file', gstImage);
        formData.append('firm', firm);
        formData.append('pan', pan);
        formData.append('gst', GST);
        formData.append('business', business);
        formData.append('client_id', client_id);
        formData.append('userType', 'LorryBroker');

        axios.post('https://pmapi.emedha.in/business/data/add', formData).then((res) => {
            if (res.data.status === 200) {
                GstUpdate()
                setOpen(false)
                setWait7(false)
            } else {
                GstError(res.data.message)
                setOpen(false)
            }
        })
    }

    const gstNo = () => {
        const email = sessionStorage.getItem('email')
        const userType = "LorryBroker"
        axios.post('https://pmapi.emedha.in/business/data/no-gst', {
            email,
            userType
        }).then((res) => {
            NoGstAl()
            setIsForm(false)
            setIsBank(false)
            setIsGst(false)
            setIsGstOpt(false)
            setIsprofileSubmenu(false)
        })
    }

    const [gstAvle, setGstAvle] = useState('')

    const yesNoGst = (gst) => {
        if (gst === 'Yes') {
            setIsGstOpt(false)
            setIsGst(true)
            setIsForm(true)
            setIsAadhar(false)
            setIsBank(false)
            setIsOtp(false)
            setGstAvle('Yes')
            kycStatus()
        } else {
            gstNo()
            setGstAvle('No')
            kycStatus()
            setIsUpdateGst(false)
        }
    }


    const [isKyc, setIsKyc] = React.useState()
    const [isGstDisabled, setIsGstDisabled] = useState(false)

    const kycStatus = () => {
        const email = sessionStorage.getItem('email')
        const type = sessionStorage.getItem('type')
        axios.post('https://pmapi.emedha.in/users/kyc/status', {
            email,
            type
        }).then((res) => {
            setIsKyc(res.data.isAddGst)
            if (res.data.isAddGst === 1) {
                setGstAvle('Yes')
                setIsGstDisabled(true)
            }
        })
    }

    useEffect(() => {
        kycStatus()
    }, []);

    const showGst = () => {
        const client_id = sessionStorage.getItem('client_id')
        const type = 'LorryBroker'
        axios.post('https://pmapi.emedha.in/business/data/show', {
            client_id,
            type
        }).then((res) => {
            setGst(res.data.gst)
            setBusiness(res.data.business)
            setFirm(res.data.firm)
            setPan(res.data.pan)
        })
    }

    useEffect(() => {
        showGst()
    }, [])

    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
        setIsOtp(false)
        setWait7(false)
    };

    const [isUpdateGst, setIsUpdateGst] = useState(false)

    return (
        <div>
            <h4>Business Details</h4>
            <p style={{ fontSize: '0.8rem' }}>A business description is a high-level overview of your company that you include in your business plan. Your business description should entice readers—like lenders and investors—to look through the rest of your business plan to learn more about your company.</p>
            <Form noValidate validated={GstValidated} onSubmit={handleSubmitGst} >
                <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label>Gst Number</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Gst Number"
                            value={GST || ''}
                            disabled={isGstDisabled}
                            style={{ padding: '0.8rem' }}
                            onChange={(e) => {
                                setGst(e.target.value)
                            }}

                        />
                        {
                            isKyc === 1 && (
                                <div className='d-flex' >
                                    <button disabled={true} style={{ position: 'relative', right: '0.6rem', fontWeight: 'bold', color: 'rgb(19, 192, 19)', border: 'none', backgroundColor: 'white', marginTop: '0.2rem', borderRadius: '1rem' }} > <img style={{ height: '22px', width: '22px', position: 'relative', top: '-0.12rem' }} src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' />Verified</button>

                                </div>
                            )
                        }
                        <Form.Control.Feedback type="invalid">
                            Please Enter Valid GST Number
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                     
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Label>Pan Card Number</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Pan Card Number"
                            aria-describedby="inputGroupPrepend"
                            required
                            value={pan || ''}
                            disabled={isGstDisabled}
                            style={{ padding: '0.8rem' }}
                            onChange={(e) => {
                                setPan(e.target.value)
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please Enter Valid Pan Card Number .
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                        <Form.Label>GST Image </Form.Label>

                        <InputGroup hasValidation>
                            <Form.Control
                                type="file"
                                aria-describedby="inputGroupPrepend"
                                required
                                style={{ padding: '0.8rem' }}
                                disabled={isGstDisabled}
                                onChange={(e) => {
                                    setGstImage(e.target.files[0])
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please Enter Valid GST Image .
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <br />
                <div className='d-flex'>
                    <Button disabled={isKyc ? true : false} className='mr-2' type='submit' onClick={() => setIsUpdateGst(true)} variant="contained" style={{ backgroundColor: !isKyc ? '#12124e' : '', padding: '0.6rem', width: '9rem' }}>
                        {
                            (isKyc === 1 || isKyc === 2) ? (
                                "Verified"
                            ) : (
                                "Submit"
                            )
                        }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default GstKyc