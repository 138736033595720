import './index.css'
import { Button } from 'react-bootstrap'
import './index.css'
import { useAppStore } from '../AppStore'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CgDetailsMore } from 'react-icons/cg'
import StoreProductDetails from '../StoreProductDetailView'
import { useEffect, useState } from 'react'
import axios from 'axios'
import AdminNavbar from '../AdminNavbar'



const AdminStore = () => {

    const [allProduct, setAllProduct] = useState([])

    const getAllProduct = async () => {
        const res = await axios.get("https://vyaparapi.emedha.in/admin-all-product/get")
        setAllProduct(res.data)
        console.log(res.data)
    }

    useEffect(() => {
        getAllProduct()


    }, [])

    const adminAprove=(email,first_name,pName,pPhoto,pPrice,discount,discription,location,id,mobile,companyName,validity)=>{
    axios.post('https://vyaparapi.emedha.in/admin-publish-product',{
        email,
        first_name,
        mobile,
        first_name,
        pName,
        pPhoto,
        pPrice,
        discount,
        discription,
        location,
        id,
        companyName,
        validity
    }).then((res)=>{
        if(res.data){
            alert(res.data)
            getAllProduct()
        }
    })
  }

  const deleteData=(id)=>{

    axios.post('https://vyaparapi.emedha.in/admin-post-user-product/delete',{
        id
    }).then((res)=>{
         alert(res.data)
         getAllProduct()
    })
  }




    return (
        <><AdminNavbar />

            <div className='mystore-container mt-4' style={{height:'38rem',overflow:'scroll'}}>
                <div className='products-store-container1'>
                    <div className='store-head-card' style={{ backgroundColor: '#02375a' }}>
                        <h1 className='my-store-heading' style={{ color: 'white' }}>E-Vyapar Admin Store</h1>
                        <hr />
                    </div>
                    <div className='container'>
                        <div className='row store-product-container'>
                            {
                                allProduct.map((data) => {

                                    return (

                                        <div className='col-lg-3 gy-2 mx-auto  store-product-card' style={{ backgroundColor: 'white' }}>
                                            <img className='store-product-img' width='100%' src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} />

                                            <h1 className='store-product-name'>{data.pName}</h1>
                                            <div className='store-product-content'>

                                                <p className='validity'>Validity: {data.validity}</p>
                                                <p className='store-product-price'>Price: {data.pPrice - data.discount / 100 * data.pPrice} <span className='discount-amount' >{data.pPrice}</span></p>
                                                <p className='store-product-discount'>Discount: <span style={{ color: 'green', fontSize: 16 }}> {data.discount}%</span></p>
                                                <div className='d-flex flex-row'>
                                                    <Button className='buy-product-btn mb-3 mt-0 mt-2' variant="outline-primary" onClick={()=>adminAprove(data.email,data.first_name,data.pName,data.pPhoto,data.pPrice,data.discount,data.discription,data.location,data.id,data.mobile,data.companyName,data.validity)}>Approve</Button>
                                                    <Button className='buy-product-btn mb-3 mt-0 mt-2' variant="outline-danger" style={{ marginLeft: 20 }} onClick={()=>deleteData(data.id)} >Delete</Button>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default AdminStore