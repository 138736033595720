import './index.css'
import { useAppStore } from '../AppStore/index'
import * as React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import { GiHamburgerMenu } from 'react-icons/gi'
import './index.css'
import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Routes, Route, Link } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import { TbLogout } from 'react-icons/tb'
import { BsSearch } from 'react-icons/bs';
import { FaUserCircle } from 'react-icons/fa';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import MyStore from '../MyStore'
import NewReferral from '../NewReferral';
import AllProducts from '../AllProducts';
import ReferralIn from '../ReferralIn';
import ReferralClose from '../ReferralClose';
import { MdKeyboardArrowDown } from 'react-icons/md'
import UpdateKyc from '../UpdateKyc';




const Dashboard = () => {


    const updateState = useAppStore((state) => state.updateOpen)

    const dopen = useAppStore((state) => state.dopen)


    const onToggleSidebar = () => {
        updateState(!dopen)
    }

    const onSetAlert = () => {
        const confirm = window.confirm("Are you sure,do you want to logout?");
        if (confirm) {
            sessionStorage.removeItem('key')
            sessionStorage.removeItem('authenticated')

        } else {

            window.location.href = window.location.href;
        }
    }


    const [lgShow, setLgShow] = useState()

    const [searchInput, setSearchInput] = useState()

    const onChangeSearchInput = (e) => {
        setSearchInput(e.target.value)
    }


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [isMyproduct, setIsMyproduct] = useState(false)
    const [isNewRefferal, setIsNewRefferal] = useState(false)
    const [isRefferalClose, setIsRefferalClose] = useState(false)
    const [isRefferalIn, setIsRefferalIn] = useState(false)
    const [isAddProduct, setIsAddProduct] = useState(false)
    const [isAllProduct, setIsAllProduct] = useState(true)
    const [isUpdateKyc, setIsUpdateKyc] = useState(false)



    return (
        <>

            <Navbar variant='dark' style={{ paddingLeft: 10, color: 'white' }} fixed="top" className="navbar1 d-flex flex-row  align-items-center justify-content-between">

                <div className='nav-width1 mobile-space'>

                    <Navbar className="d-flex flex-row  align-items-center ">

                        <Navbar.Brand href="#home">
                            <h1 className='logo-heading' style={{ color: 'white' }}>E-<span style={{ color: '#062d59' }}>Vyapar</span> Store</h1>



                        </Navbar.Brand>
                        <div className="search-input-container-navbar">
                            <input
                                value={searchInput}
                                type="search"
                                className="search-input-navbar"
                                placeholder="Search"
                                onChange={onChangeSearchInput}
                            // onKeyDown={onEnterSearchInput}
                            />
                            <BsSearch className="search-icon" style={{ color: 'black', fontSize: 20 }} />
                        </div>


                    </Navbar>

                    <div className='small-md-screen'>
                        {dopen ?
                            <IoMdClose className='icon' style={{ fontSize: 40, color: '#1c1f18' }} onClick={onToggleSidebar} /> : <GiHamburgerMenu style={{ color: 'white', fontSize: 35, marginRight: 20 }} onClick={onToggleSidebar} />}

                    </div>

                </div>

                <div className='large-screen'>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className='nav-items' style={{ marginRight: 15 }}>
                            <Link onClick={() => {
                                setIsNewRefferal(false)
                                setIsUpdateKyc(true)
                                setIsAddProduct(false)
                                setIsMyproduct(false)
                                setIsRefferalIn(false)
                                setIsRefferalClose(false)
                                setIsAllProduct(false)
                            }} style={{ textDecoration: 'none' }}><Button style={{ backgroundColor: 'white', width: 125, marginLeft: 10, color: '#d9370d', borderColor: '#d9370d', borderRadius: 3 }}>Update Kyc</Button> </Link>
                            <div>
                                <Nav style={{ cursor: 'pointer' }} onClick={handleClick} ><h1 style={{ marginLeft: 15, fontSize: 30, color: 'black' }}><FaUserCircle /></h1> </Nav>

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Typography sx={{ pl: 3, pt: 2, fontSize: 16 }}>Profile</Typography>
                                    <Typography sx={{ pl: 3, pr: 2, pb: 3, pt: 2, fontSize: 16 }}>   {sessionStorage.getItem(`key`) && (
                                        <Link onClick={onSetAlert} style={{ textDecoration: 'none', fontSize: 16, color: 'red' }}> Logout<TbLogout style={{ marginLeft: 5 }} /></Link>
                                    )}</Typography>
                                </Popover>
                            </div>

                        </Nav>
                    </Navbar.Collapse>
                </div>

            </Navbar>

            <div className='hide-navbar'>
                <Navbar variant='light' style={{ paddingLeft: 10, color: 'white' }} fixed="top" className="navbar2 d-flex flex-row justify-content-between">


                    <div className='large-screen2 col-md-12 col-xl-11 mx-auto d-md-flex d-none'>
                        <center>
                            <Navbar.Collapse id="basic-navbar-nav" >
                                <Nav style={{ fontWeight: 'bold', marginRight: 15, color: 'black' }}>
                                    <Typography onClick={() => {
                                        setIsNewRefferal(false)
                                        setIsAddProduct(false)
                                        setIsMyproduct(false)
                                        setIsRefferalIn(false)
                                        setIsRefferalClose(false)
                                        setIsAllProduct(true)
                                        setIsUpdateKyc(false)
                                        window.location.reload()
                                    }} to='/all-products' style={{ cursor: 'pointer', marginRight: 15, marginBottom: 7.21, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>All Products</Typography>
                                    <Typography onClick={() => {
                                        setIsNewRefferal(true)
                                        setIsAddProduct(false)
                                        setIsMyproduct(false)
                                        setIsRefferalIn(false)
                                        setIsRefferalClose(false)
                                        setIsAllProduct(false)
                                    }} to='new-referral' style={{ cursor: 'pointer', marginRight: 15, marginBottom: 7.21, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral Out</Typography>
                                    <Typography onClick={() => {
                                        setIsNewRefferal(false)
                                        setIsAddProduct(false)
                                        setIsMyproduct(false)
                                        setIsRefferalIn(true)
                                        setIsRefferalClose(false)
                                        setIsAllProduct(false)
                                        setIsUpdateKyc(false)
                                    }} to='referral-in' style={{ cursor: 'pointer', marginRight: 15, marginBottom: 7.21, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral In</Typography  >
                                    <Typography onClick={() => {
                                        setIsNewRefferal(false)
                                        setIsAddProduct(false)
                                        setIsMyproduct(false)
                                        setIsRefferalIn(false)
                                        setIsRefferalClose(true)
                                        setIsAllProduct(false)
                                        setIsUpdateKyc(false)
                                    }} to='referral-close' style={{ cursor: 'pointer', marginRight: 15, marginBottom: 7.21, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>Referral Close</Typography  >
                                    <Typography onClick={() => {
                                        setIsNewRefferal(false)
                                        setIsAddProduct(false)
                                        setIsMyproduct(true)
                                        setIsRefferalIn(false)
                                        setIsRefferalClose(false)
                                        setIsAllProduct(false)
                                        setIsUpdateKyc(false)
                                    }} style={{ cursor: 'pointer', marginRight: 15, marginBottom: 7.21, textDecoration: 'none', fontWeight: 400 }} className='nav-2-items'>My Product </Typography>
                                </Nav>
                            </Navbar.Collapse>
                        </center>
                    </div>


                </Navbar>
            </div>

            <Modal
                size="xl"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                backgroundColor='#e3010f'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Filter
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row mx-auto referral-in-product'>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {
                isNewRefferal && (
                    <NewReferral></NewReferral>
                )
            }
            {
                isAllProduct && (
                    <AllProducts></AllProducts>
                )
            }
            {
                isMyproduct && (
                    <MyStore></MyStore>
                )
            }
            {
                isRefferalIn && (
                    <ReferralIn></ReferralIn>
                )
            }
            {
                isRefferalClose && (
                    <ReferralClose></ReferralClose>

                )
            }
            {
                isUpdateKyc && (
                    <UpdateKyc></UpdateKyc>
                )
            }
        </>



    )
}


export default Dashboard