import { Component, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Container, Row, Col } from 'react-bootstrap'

import './index.css'

import RegisterPageSlider from '../RegisterPageSlider';
import NavbarEvyapar from '../NavbarEvyapar';

class SignupPage extends Component {

    state = { citys: [], states: [], businessVerticalList: [], subVerticalList: [], classificationList: [], isAlert: false, isShowError: false, isFormSubmitted: false, gender: '', first_name: '', last_name: '', email: '', mobile: '', companyName: '', state: '', city: '', address: '', showAddressErr: false, businessVertical: '', subVertical: '', classification: '', password: '', confirmpassword: '', showConfirmPasswordErr: false, showFirstnameErr: false, showEmailErr: false, showMobileErr: false, showCompanyErr: false, showBusinessErr: false, showPasswordErr: false }


    onChangeFirstname = (event) => {
        this.setState({ first_name: event.target.value })
    }

    onChangeEmail = (event) => {
        this.setState({ email: event.target.value })
    }

    onChangeMobilenum = (event) => {
        this.setState({ mobile: event.target.value })
    }
    onChangeCity = event => {
        this.setState({ city: event.target.value })
    }

    onChangeState = event => {
        this.setState({ state: event.target.value })
    }
    onChangeLastname = (event) => {
        this.setState({ last_name: event.target.value })
    }

    onChangeCompanyName = (event) => {
        this.setState({ companyName: event.target.value })
    }

    onChangeBusiness = event => {
        this.setState({ businessVertical: event.target.value })
    }

    onChangeSubvertical = event => {
        this.setState({ subVertical: event.target.value })
    }

    onChangeClassification = (event) => {
        this.setState({ classification: event.target.value })
    }

    onChangePassword = event => {
        this.setState({ password: event.target.value })
    }

    onChangeConfimPassword = event => {
        this.setState({ confirmpassword: event.target.value })
    }

    validateFirstname = () => {
        const { first_name } = this.state
        return first_name !== ''
    }

    validateEmail = () => {
        const { email } = this.state
        return email !== ""
    }

    validateMobile = () => {
        const { mobile } = this.state
        return mobile !== ""
    }
    validateAddress = () => {
        const { address } = this.state
        return address !== ""
    }

    validateCompanyname = () => {
        const { company } = this.state
        return company !== ''
    }

    validateBusiness = () => {
        const { business } = this.state
        return business !== ''
    }

    onValueChange = (event) => {
        this.setState({
            gender: event.target.value
        });
    }

    validatePassword = () => {
        const { password } = this.state
        return password !== ''
    }

    validateConfirmPassword = () => {
        const { confirmpassword } = this.state
        return confirmpassword !== ''
    }

    onBlurFirstname = () => {
        const validateFirstname = this.validateFirstname()
        this.setState({ showFirstnameErr: !validateFirstname })
    }

    onBlurEmail = () => {
        const validateEmail = this.validateEmail()
        this.setState({ showEmailErr: !validateEmail })
    }

    onBlurMobile = () => {
        const validateMobile = this.validateMobile()
        this.setState({ showMobileErr: !validateMobile })
    }

    onBlurAddress = () => {
        const validateAddress = this.validateAddress()
        this.setState({ showAddressErr: !validateAddress })
    }

    onBlurCompanyname = () => {
        const validateCompanyname = this.validateCompanyname()
        this.setState({ showCompanyErr: !validateCompanyname })
    }

    onBlurBusiness = () => {
        const validateBusiness = this.validateBusiness()
        this.setState({ showBusinessErr: !validateBusiness })
    }

    onBlurPassword = () => {
        const validatePassword = this.validatePassword()
        this.setState({ showPasswordErr: !validatePassword })
    }
    onBlurConfirmPassword = () => {
        const validateConfirmPassword = this.validateConfirmPassword()
        this.setState({ showConfirmPasswordErr: !validateConfirmPassword })
    }
    onChangeAddress = (event) => {
        this.setState({ address: event.target.value })
    }

    onClickAlert = () => {
        this.setState({ isAlert: true })
    }


    componentDidMount() {
        this.businessVertical()

        // this.classification()
    }


    businessVertical = async () => {

        const apiUrl = `https://vyaparapi.emedha.in/business-vertical`
        const type = 'businessVertical'
        axios.post(apiUrl, {
            type
        }).then((res) => {
            this.setState({ businessVerticalList: res.data })
            console.log(res.data)
        })
    }





    subVertical = async (mapNumber) => {


        const apiUrl = 'https://vyaparapi.emedha.in/sub-vertical'

        axios.post(apiUrl, {
            mapNumber
        }).then((res) => {
            this.setState({ subVerticalList: res.data })
        })


    }


    classification = async (mapNumber) => {



        const apiUrl = 'https://vyaparapi.emedha.in/classification'

        axios.post(apiUrl, {
            mapNumber
        }).then((res) => {

            this.setState({ classificationList: res.data })

        })


    }


    // const [citys, setCitys] = useState([])
    // const [states, setStates] = useState([])
    // const [isState,setIsState] = useState(false)
    // const [isCity,setIsCity] = useState(false)

     cityData = (mapNumber) => {



        axios.post('https://vyaparapi.emedha.in/city',{
            mapNumber
        }).then((res) => {
            this.setState({citys:res.data})
        })
    }

    componentDidMount() {
        this.StateData()
    }


    StateData = () => {

        const apiUrl = 'https://vyaparapi.emedha.in/state'

        axios.get(apiUrl).then((res) => {
            this.setState({ states: res.data })
            console.log(res.data)
        })


    }






    onSubmitForm = (event) => {
        event.preventDefault()
        const validateFirstname = this.validateFirstname()
        const validateEmail = this.validateEmail()
        const validateMobile = this.validateMobile()
        const validateCompanyname = this.validateCompanyname()
        const validateBusiness = this.validateBusiness()
        const validatePassword = this.validatePassword()
        const validateConfirmPassword = this.validateConfirmPassword()



        if (validateFirstname && validateEmail && validateMobile && validateCompanyname && validateBusiness && validatePassword && validateConfirmPassword) {
            this.setState({ isFormSubmitted: true })

            const signemail = {
                signupemail: this.state.email
            }

            const email = signemail.signupemail

            const firstNameUser = {
                firstname: this.state.first_name
            }

            const first_name = firstNameUser.firstname

            const lastNameUser = {
                lastname: this.state.last_name
            }



            const last_name = lastNameUser.lastname

            const mobileNumber = {
                mobileNo: this.state.mobile
            }

            const mobile = mobileNumber.mobileNo

            const gender_type = {
                gendertype: this.state.gender
            }

            const gender = gender_type.gendertype

            const cityName = {
                city_name: this.state.city
            }

            const city = cityName.city_name

            const addressUser = {
                address_user: this.state.address
            }

            const address = addressUser.address_user

            const stateName = {
                state_name: this.state.state
            }

            const state = stateName.state_name

            const companyType = {
                company_name: this.state.companyName
            }

            const companyName = companyType.company_name

            const businessVerticals = {
                business_vertical: this.state.businessVertical

            }

            const businessVertical = businessVerticals.business_vertical

            const subVerticals = {
                sub_vertical: this.state.subVertical
            }

            const subVertical = subVerticals.sub_vertical

            const classificationType = {
                classification_type: this.state.classification

            }

            const classification = classificationType.classification_type


            const password = this.state.password

            // const navigate=useNavigate()



            axios.post('http://localhost:9005/signup', {
                email,
                first_name,
                last_name,
                mobile,
                gender,
                city,
                address,
                state,
                companyName,
                businessVertical,
                subVertical,
                classification

            })
                .then(res => {

                    if (res.data.status === 200) {
                        alert(res.data.message)
                        window.location.href = `/otp/${email}/${password}`

                    }
                    else {
                        alert(res.data)
                    }
                })




        }
        else {
            this.setState({ isShowError: true, showFirstnameErr: !validateFirstname, showEmailErr: !validateEmail, showMobileErr: !validateMobile, showCompanyErr: !validateCompanyname, showBusinessErr: !validateBusiness, showPasswordErr: !validatePassword, showConfirmPasswordErr: !validateConfirmPassword, isFormSubmitted: false })
        }




    }



    render() {
        const {states,citys, businessVerticalList, subVerticalList, classificationList, isFormSubmitted, isAlert, isShowError, first_name, last_name, email, password, gender, subVertical, classification, confirmpassword, showAddressErr, mobile, state, city, address, companyName, businessVertical, showPasswordErr, showConfirmPasswordErr, showFirstnameErr, showEmailErr, showMobileErr, showCompanyErr, showBusinessErr } = this.state
       console.log(businessVerticalList)
        return (
            <>

                <div className='container signup-container'>
                    <div className='row d-flex flex-row align-items-center'>
                        <div className='col-lg-5 mx-auto text-center signup-logo'>
                            {/* <img src="https://img.freepik.com/premium-vector/online-registration-illustration-design-concept-websites-landing-pages-other_108061-939.jpg?w=740" className='signup-logo' style={{ borderRadius: 8, width: 450 }} alt='logo'></img> */}
                            <RegisterPageSlider />

                            <br />
                            <br />
                            <h5 className="login-heading" style={{ fontWeight: 800, color: 'black' }}>Welcome To  <span style={{ color: 'rgb(13, 10, 70)', fontSize: 20 }}>e-Vyapar</span> <span style={{ color: '#d9370d', fontSize: 23 }}>Exchange</span></h5>
                            <p className="text-center pt-4" style={{ fontSize: '1.2rem', color: 'rgb(13, 10, 70)', marginLeft: 15 }}>Create a E-vyapar Login Account </p>
                        </div>
                        <div className='col-lg-6 mx-auto m-2 border-0 form-top-container' style={{ border: '1px solid black', borderRadius: 5 }}>
                            <h1 className='create-acc-heading' style={{ fontFamily: "Roboto" }}>Create an account</h1>
                            <Form className='p-3 ' onSubmit={this.onSubmitForm} style={{ border: '1px solid lightgray', borderRadius: 8 }}>
                                <Form.Group className="mb-2">
                                    <div className='two-input-box mt-2'>

                                        <Form.Control placeholder="First name" id='first_name' value={first_name} className={showFirstnameErr ? `input-box-small-err` : `input-box-small`} type='text' onChange={this.onChangeFirstname} onBlur={this.onBlurFirstname} />


                                        <Form.Control placeholder="Last name" className='input-box-small' type='text' id='last_name' value={last_name} onChange={this.onChangeLastname} />
                                    </div>

                                    <Form.Control placeholder="Enter email" className={showEmailErr ? `input-box-large-err` : `input-box-large`} type='email' id='email' value={email} onChange={this.onChangeEmail} onBlur={this.onBlurEmail} />
                                    <div className='two-input-box'>
                                        <Form.Control placeholder="Mobile number" className={showMobileErr ? `input-box-small-err` : `input-box-small`} type='tel' id='mobile' value={mobile} onChange={this.onChangeMobilenum} onBlur={this.onBlurMobile} />
                                        {/* <Form.Control placeholder="State" className='input-box-small' type='text' id='state' value={state} onChange={this.onChangeState} /> */}
                                        <Form.Select className='input-box-small' onChange={(e) => this.cityData(e.target.value)}>
                                            <option value="" disabled selected>Select State</option>
                                            {states.map((eachOption) =>

                                                <option value={eachOption.sno}>{eachOption.name}</option>)}

                                        </Form.Select>

                                    </div>
                                    <div className='two-input-box'>
                                    <Form.Select className='input-box-small mt-2'>
                                            <option value="" disabled selected>Select City</option>
                                            {citys.map((eachOption) =>

                                                <option value={eachOption.sno}>{eachOption.name}</option>)}

                                        </Form.Select>
                                        <textarea type='text' id='address' placeholder='Address' value={address} className={showAddressErr ? `input-box-address-err` : `input-box-address`} onBlur={this.onBlurAddress} onChange={this.onChangeAddress}></textarea>
                                    </div>
                                    <Form.Group className="mb-2 d-flex flex-row justify-content-between align-items-center">
                                        <Form.Label className='mr-2'>Gender:</Form.Label>
                                        <div className='mb-1'>
                                            <input type="radio" name="Gender" value="Male" id='Male' onChange={this.onValueChange} checked={this.state.gender === 'Male'} />
                                            <label htmlFor='Male' style={{ marginRight: 10, marginLeft: 4 }}>Male</label>
                                            <input type="radio" name="Gender" value="Female" id='Female' onChange={this.onValueChange} checked={this.state.gender === 'Female'} />
                                            <label htmlFor='Female' style={{ marginRight: 10, marginLeft: 4 }}>Female</label>
                                            <input type="radio" name="Gender" value="Other" id='Other' onChange={this.onValueChange} checked={this.state.gender === 'Other'} />
                                            <label htmlFor='Other' style={{ marginRight: 10, marginLeft: 4 }}>Other</label>
                                        </div>
                                    </Form.Group>

                                    <Form.Control placeholder="Company Name" id='companyName' value={companyName} className={showCompanyErr ? `input-box-large-err` : `input-box-large`} type='text' onBlur={this.onBlurCompanyname} onChange={this.onChangeCompanyName} />

                                    <div className='two-input-box'>
                                        <Form.Select className='input-box-small' onChange={(e) => this.subVertical(e.target.value)}>
                                            <option value="" disabled selected>Business Vertical Type</option>
                                            {businessVerticalList.map((eachOption) =>

                                                <option value={eachOption.sno}>{eachOption.name}</option>)}

                                        </Form.Select>

                                        <Form.Select className='input-box-small' onChange={(e) => this.classification(e.target.value)}>
                                            <option value="" disabled selected >Sub Vertical Type</option>
                                            {subVerticalList.map((eachOption) =>

                                                <option value={eachOption.sno}>{eachOption.name}</option>)}

                                        </Form.Select>
                                    </div>
                                    <Form.Select className='classification'>
                                        <option value="" disabled selected >classification Type</option>
                                        {classificationList.map((eachOption) =>

                                            <option value={eachOption.sno}>{eachOption.name}</option>)}

                                    </Form.Select>

                                    <div className='two-input-box'>

                                        <Form.Control className={showPasswordErr ? `input-box-small-err` : `input-box-small`} type='password' id='password' value={password} placeholder="Enter Password" onChange={this.onChangePassword} onBlur={this.onBlurPassword} />
                                        <Form.Control className={showConfirmPasswordErr ? `input-box-small-err` : `input-box-small`} type='password' placeholder="Confirm Password" id='confirmpassword' value={confirmpassword} onChange={this.onChangeConfimPassword} onBlur={this.onBlurConfirmPassword} />
                                    </div>
                                </Form.Group>
                                {/* <Form.Group className="mb-2">
                                <Form.Label>Location</Form.Label>
                                <Form.Select>
                                    <option>Hyderabad</option>
                                    <option>Chennai</option>
                                    <option>Mumbai</option>
                                </Form.Select>
                            </Form.Group> */}
                                {/* <Form.Group className="mb-2">
                                <Form.Label>User Type</Form.Label>
                                <Form.Select>
                                    <option>Retail Customer</option>
                                    <option>Corporate Customer</option>
                                    <option>Lorry Wala</option>
                                    <option>Lorry Broker</option>
                                </Form.Select>
                            </Form.Group>  */}
                                <div className='d-flex flex-column'>
                                    {isFormSubmitted ? <button type='submit' style={{ color: 'black', borderRadius: 5, padding: 10, marginBottom: 20, marginTop: 13 }} disabled>Sending....</button> : <button type='submit' style={{ backgroundColor: '#0d0a46', color: 'white', borderRadius: 5, padding: 10, marginBottom: 20, marginTop: 13 }} onClick={this.onClickAlert}>SIGNUP</button>}
                                    <p className='text-center' style={{ fontSize: 16 }}>ALREADY HAVE AN ACCOUNT? <a href='/' className='span-button' style={{ color: '#d9370d' }}>SIGNIN</a></p>
                                </div>
                                {isShowError ? <p style={{ color: 'red' }} className='text-center'>Please Enter All Required* Fields</p> : null}
                            </Form>

                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default SignupPage;

 
 