import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import OtpInput from "react-otp-input";
import { Button } from '@mui/material';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { FaEdit, FaWindowClose } from 'react-icons/fa';
import Swal from "sweetalert2";

const AadharKyc = () => {

    const AadharUpdate = () => {
        Swal.fire({
            title: "Successful..!",
            cancelButtonText: 'Cancel',
            text: "Your aadhar number successfully updated...!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Dashboard",
        }).then((result) => {
            if (result.value) {
                window.location.reload()
            } else {
                setOpen(false)
            }
        });
    }

    const AadharTime = () => {
        Swal.fire({
            title: "Time Out..!",
            cancelButtonText: 'Cancel',
            text: "Please try again after some time ...!",

            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",

        }).then((result) => {
            if (result.value) {

            } else {
                setOpen(false)
            }
        });
    }

    const AadharOtpError = () => {
        Swal.fire({
            title: "Somthing Wrong..!",
            text: "Please try again later ...!",
            showCancelButton:  false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            
        }).then((result) => {
            if (result.value) {
                
            } else {
                setOpen(false)
            }
        });
    }

    const [isAadhar, setIsAadhar] = useState(false)
    const [Aadhar, setAadhar] = useState('')
    const [isBackDrop, setIsBackDrop] = useState(false)
    const [AadharValidated, setAadharValidated] = useState(false);
    const [isSuccessKyc, setIsSuccessKyc] = useState(false)

    const handleSubmitAadhar = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            generateOtp(Aadhar)
        }
        setAadharValidated(true);
    };

    const handleChange = (otp) => {
        setOtp(otp)
    };

    const addAadhar = (Aadhar) => {
        const client_id = sessionStorage.getItem('client_id')
        const userType = 'LorryBroker'
        axios.post('https://pmapi.emedha.in/users/kyc', {
            Aadhar,
            client_id,
            userType
        }).then((res) => {
            if (res.data.status === 200) {
                setAadhar('')
                setIsBackDrop(false)
                setIsSuccessKyc(true)
                setTimeout(() => setIsSuccessKyc(false), 4500)
                setModelOtp(false)
                AadharUpdate()
                setOpen(false)
                setOtp('')
            }

        })
    }


    const aadharVerification = (otp) => {
        setIsBackDrop(true)
        axios.post('http://localhost:9005/e-vyapar/v1/submit/otp', {
            ref_id,
            otp
        }).then((res) => {
            if (res.data.statusCode === 200) {
                const CCEncoded = "XXXX XXXX " + Aadhar.substring(Aadhar.length + 4, 8);
                setIsOtp(false)
                AadharUpdate()
                setOpen(false)
            }else{
                AadharOtpError()
            }
        }).catch((err) => {
            AadharOtpError()
            setIsBackDrop(false)
        })
    }

    // '780272918050'

    const [isOtp, setIsOtp] = useState(false)
    const [otp, setOtp] = useState(null)
    const [client_id, set_client_id] = useState('')
    const [ref_id, set_ref_id] = useState()

    const generateOtp = (aadharNumber) => {
        setWait4(true)
        axios.post('http://localhost:9005/e-vyapar/v1/generate/otp', {
            aadharNumber
        }).then((res) => {
            if (res.data.statusCode === 200) {
                setIsAadhar(false)
                setIsBackDrop(false)
                setModelOtp(true)
                setWait4(false)
                setIsOtp(true)
                set_ref_id(res.data.ref_id)
            } else {
                AadharTime()
            }
        })
    }

    const [aIsAdd, setAisAdd] = useState(false)

    const isKyc = () => {
        const email = sessionStorage.getItem('email')
        const type = "LorryBroker"
        axios.post('https://pmapi.emedha.in/users/kyc/status', {
            email,
            type
        }).then((res) => {
            if (res.data.isAadhar === 1) {
                setAisAdd(true)
            }
        })
    }

    const [aStore, setAstore] = useState([])

    const getAadhar = () => {

        const client_id = sessionStorage.getItem('client_id')
        const dataReq = 'aadhar_number'

        axios.post('https://pmapi.emedha.in/users/lorry_broker', {
            client_id,
            dataReq
        }).then((res) => {
            setAstore(res.data.data)
        })
    }

    useEffect(() => {
        getAadhar()
        isKyc()
    }, [])


    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
        setIsOtp(false)
        setModelOtp(false)
        setUpdateAdhar(false)
    };

    const [mainOtp, setMainOtp] = useState(false)

    const [isModelotp, setModelOtp] = useState(false)
    const [wait4, setWait4] = useState(false)
    const [updateAadhar, setUpdateAdhar] = useState(false)

    return (
        <div>
            <div className='col-lg-11 mx-auto pb-4 p-4 pb-5 ' style={{ marginTop: '2rem' }} >
                {
                    (!isOtp && !aIsAdd) && (
                        <div>

                            <Form noValidate validated={AadharValidated} onSubmit={(e) => {
                                handleSubmitAadhar(e)
                                setMainOtp(true)
                            }} >
                                <Row className="mb-3">
                                    <center><img width='150px' src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' /></center>
                                    <h4>Aadhar Details</h4>
                                    <p>We need the ID proof of the business owner for verification purposes.</p>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Aadhar Number</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="EX: 9475 7252 8356"
                                            style={{ padding: '0.8rem' }}
                                            onChange={(e) => {
                                                setAadhar(e.target.value)
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Aadhar Number
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <div style={{ height: '4rem', width: '19rem', backgroundColor: 'rgb(243, 242, 242)', marginBottom: '0.5rem', borderRadius: '0.4rem', padding: '0.2rem' }} >
                                    <span style={{ fontWeight: 'bold', color: 'black', fontSize: 20 }} >Note:</span> <span style={{ color: 'gray', fontSize: 13 }}> OTP will be sent to the mobile number linked to this aadhaar.</span>
                                </div>
                                {
                                    !wait4 && (
                                        <Button type='submit' variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem', marginTop: 59 }}>  Sent OTP</Button>
                                    )
                                }
                                {
                                    wait4 && (
                                        <Button disabled={true} type='submit' variant="contained" style={{ padding: '0.6rem', width: '9rem', marginTop: 59 }}> Sending...</Button>
                                    )
                                }
                            </Form>
                        </div>
                    )
                }

                {
                    aIsAdd && (
                        <div style={{ marginBottom: '0.5rem' }}>
                            {
                                aStore.map((data) => {
                                    return (
                                        <div>

                                            <center><img width='150px' src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' /></center>
                                            <Form noValidate validated={AadharValidated} onSubmit={handleSubmitAadhar} >
                                                <div onClick={() => setOpen(true)} className='card' style={{ height: '1.4rem', border: '1px solid #12124e', borderRadius: '0.2rem', color: '#12124e', fontWeight: 'bold', width: '13rem', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', position: 'relative', top: 29, left: 180 }}><FaEdit size={13} style={{ position: 'relative', top: '-0.1rem' }} />Update Aadhar Details</div>
                                                <h4>Aadhar Details</h4>

                                                <p>We need the ID proof of the business owner for verification purposes.</p>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label>Aadhar Number</Form.Label>

                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            value={data.aadhar_card}
                                                            disabled={true}
                                                            placeholder="Aadhar Number"
                                                            style={{ padding: '0.8rem' }}
                                                            onChange={(e) => {
                                                                setAadhar(e.target.value)
                                                            }}
                                                        />
                                                        <div className='d-flex' >
                                                            <button disabled={true} style={{ position: 'relative', right: '0.6rem', fontWeight: 'bold', color: 'rgb(19, 192, 19)', border: 'none', backgroundColor: 'white', marginTop: '0.2rem', borderRadius: '1rem' }} > <img style={{ height: '22px', width: '22px', position: 'relative', top: '-0.12rem' }} src='https://th.bing.com/th?id=OIP.8ZRKpc1B-_gDhkMlCOf5CwHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=2.5&pid=3.1&rm=2' />Verified</button>

                                                        </div>
                                                    </Form.Group>
                                                </Row>
                                                <div style={{ height: '6rem', width: '23rem', backgroundColor: 'rgb(243, 242, 242)', marginBottom: '0.5rem', borderRadius: '0.4rem', padding: '0.2rem' }} >
                                                    <span style={{ fontWeight: 'bold', color: 'black', fontSize: 18 }} >Note:</span> <span style={{ color: 'gray', fontSize: 13 }}> OTP Not will be sent you already have verified by with your Aadhar Number.</span>
                                                </div>

                                                <Button disabled={true} type='submit' variant="contained"  >  Sent OTP</Button>
                                            </Form>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }

                {
                    (isOtp && mainOtp) && (
                        <div>
                            <center><img width='150px' src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' /></center>
                            <h4>Verify</h4>
                            <p>We need the ID proof of the business owner for verification purposes.</p>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Enter OTP</Form.Label>

                                <Form.Control
                                    required
                                    type="text"
                                    value={otp}

                                    placeholder="Enter OTP"
                                    style={{ padding: '0.8rem' }}
                                    onChange={(e) => {
                                        setOtp(e.target.value)
                                    }}
                                />
                                <div style={{ height: '6rem', width: '23rem', backgroundColor: 'green', marginBottom: '0.5rem', borderRadius: '0.4rem', padding: '0.2rem', marginTop: '1rem' }} >
                                    <span style={{ fontWeight: 'bold', color: 'white', fontSize: 21 }} >Note:</span> <span style={{ color: 'white', fontSize: 15 }}> OTP has been sent to the your register mobile number please check and fallow geven instruction</span>
                                </div>
                                {/* <div className='d-flex' >
                                    <button disabled={true} style={{ position: 'relative', right: '0.6rem', fontWeight: 'bold', color: 'rgb(19, 192, 19)', border: 'none', backgroundColor: 'white', marginTop: '0.2rem', borderRadius: '1rem' }} > <img style={{ height: '22px', width: '22px', position: 'relative', top: '-0.12rem' }} src='https://th.bing.com/th?id=OIP.8ZRKpc1B-_gDhkMlCOf5CwHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=2.5&pid=3.1&rm=2' />Verified</button>
                                </div> */}
                            </Form.Group>
                        </div>
                    )
                }

                <div >
                    {

                        (isOtp && mainOtp) && <Button type='submit' onClick={() => aadharVerification(otp)} variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem', marginTop: '6rem' }}>  Verify</Button>
                    }
                </div>
            </div >
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogContent style={{ padding: 20 }}>
                        <div className='card' style={{ height: '4.8rem', width: '51rem', flexDirection: 'row', alignItems: 'center', borderRadius: '0.1rem' }} >
                            <FaWindowClose onClick={() => handleClose()} style={{ fontSize: 42, color: 'lightgray', marginLeft: 10, cursor: 'pointer' }} />
                            <h4 style={{ marginTop: 9.6, marginLeft: 7, fontWeight: 'bold' }}>Update Kyc</h4>
                        </div>
                        {
                            !isModelotp && (
                                <div>
                                    <Form noValidate validated={AadharValidated} onSubmit={(e) => {
                                        handleSubmitAadhar(e)
                                        setMainOtp(false)
                                    }} >
                                        <Row className="mb-3">
                                            <center><img width='150px' src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' /></center>

                                            <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                <Form.Label>Aadhar Number</Form.Label>
                                                {
                                                    !updateAadhar && (
                                                        <div>
                                                            {
                                                                aStore.map((data) => {
                                                                    return (
                                                                        <Form.Control
                                                                            required
                                                                            type="text"
                                                                            value={data.aadhar_card}
                                                                            placeholder="Eg: 0000 0000 0000"
                                                                            readOnly
                                                                            style={{ padding: '0.8rem', cursor: 'pointer' }}
                                                                            onClick={(e) => {
                                                                                setUpdateAdhar(true)
                                                                            }}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                                {
                                                    updateAadhar && (
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Eg: 0000 0000 0000"
                                                            style={{ padding: '0.8rem' }}
                                                            onChange={(e) => {
                                                                setAadhar(e.target.value)
                                                            }}
                                                        />
                                                    )
                                                }
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter Valid Aadhar Number
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Row>
                                        <div style={{ height: '4rem', width: '19rem', backgroundColor: 'rgb(243, 242, 242)', marginBottom: '0.5rem', borderRadius: '0.4rem', padding: '0.2rem' }} >
                                            <span style={{ fontWeight: 'bold', color: 'black', fontSize: 20 }} >Note:</span> <span style={{ color: 'gray', fontSize: 13 }}> OTP will be sent to the mobile number linked to this aadhaar.</span>
                                        </div>
                                        {
                                            !wait4 && (
                                                <Button type='submit' variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem' }}>  Sent OTP</Button>
                                            )
                                        }
                                        {
                                            wait4 && (
                                                <Button disabled={true} type='submit' variant="contained" style={{ padding: '0.6rem', width: '9rem' }}> Sending...</Button>
                                            )
                                        }
                                    </Form>
                                </div>
                            )
                        }
                        {
                            isModelotp && (
                                <div>
                                    <div>
                                        <center><img width='150px' src='https://th.bing.com/th/id/OIP.3CORWtSb3iA6AhikTCZEvwHaFH?w=239&h=180&c=7&r=0&o=5&dpr=2.5&pid=1.7' /></center>
                                        <h4>Verify</h4>
                                        <p>We need the ID proof of the business owner for verification purposes.</p>
                                        <OtpInput
                                            value={otp}
                                            onChange={handleChange}
                                            numInputs={6}
                                            separator={<span style={{ width: "8px" }}></span>}
                                            isInputNum={true}
                                            shouldAutoFocus={true}
                                            className='signup-input-otp'
                                            inputStyle={{
                                                border: "none",
                                                borderRadius: "2px",
                                                width: "7rem",
                                                height: "54px",
                                                fontSize: "16px",
                                                margin: 'auto',
                                                backgroundColor: '#e4e4fa',
                                                fontWeight: "bold",
                                                caretColor: "rgba(60, 64, 67, 0.3)",
                                            }}
                                            focusStyle={{
                                                outline: "none"
                                            }}
                                        />
                                    </div>
                                    <Button type='submit' onClick={() => aadharVerification(otp)} variant="contained" style={{ backgroundColor: '#12124e', padding: '0.6rem', width: '9rem', marginTop: '0.5rem' }}>  Verify</Button>
                                </div>
                            )
                        }
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    )
}

export default AadharKyc