import { Button } from 'react-bootstrap'
import './index.css'
import { useAppStore } from '../AppStore'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CgDetailsMore } from 'react-icons/cg'
import StoreProductDetails from '../StoreProductDetailView'
import { useEffect, useState } from 'react'
import axios from 'axios'
import NavbarEvyapar from '../NavbarEvyapar'
import AddProductForm from '../AddProductForm'


const MyStore = () => {

    const openAddProductForm = () => {

        setIsAddProduct(true)
    }

    const updateState = useAppStore((state) => state.updateOpen)

    const dopen = useAppStore((state) => state.dopen)

    const [isOpenProductDeailView, setDetailProductView] = useState(false)

    const [allProducts, setAllProduct] = useState([])

    const getAllProduct = () => {
        const email = sessionStorage.getItem('email')
        axios.post("https://vyaparapi.emedha.in/my-product/get", {
            email: 'sonu123s123k@gmail.com'
        }).then((res) => {
            setAllProduct(res.data)
            console.log(res.data.length)
        })
    }

    useEffect(() => {
        getAllProduct()
    }, [])



    const getProductData = () => {
        axios.get("https://vyaparapi.emedha.in/product/get").then((resp) => {
            //set product
            console.log(resp.data)
        })
    }


    const [dis, setDis] = useState()

    useEffect(() => {
        getProductData()

        setDis(dis)
    }, [])

    const disableDate = () => {
        var today, dd, mm, yyyy;
        today = new Date();
        dd = today.getDate() + 1;
        mm = today.getMonth() + 1
        yyyy = today.getFullYear()
        return yyyy + "-" + mm + "-" + dd;
    }


    const [isAddProduct, setIsAddProduct] = useState(false)


    return (
        <>
            {
                isAddProduct && (
                    <AddProductForm></AddProductForm>
            )
            }
            {
                !isAddProduct && (
                    <div>
                        {isOpenProductDeailView ? <StoreProductDetails /> : <div className='mystore-container'>
                            <div className='products-store-container shadow'>
                                <div className='store-head-card' style={{ backgroundColor: '#02375a', borderRadius: '0.24rem' }}>
                                    <h1 className='my-store-heading' style={{ color: 'white' }}>E-Vyapar My Store</h1>
                                    <Link><Button className='add-product-btn' variant="secondary" onClick={openAddProductForm}>Add Product</Button></Link>

                                    <hr />
                                </div>
                                <div className='container'>
                                    {allProducts.length === 0 ? <div><h1>No Products</h1></div> :
                                        <div className='store-product-container'>
                                            {
                                                allProducts.map((data) => {

                                                    return (


                                                        <div className='gy-3 mx-auto mystore-product-card'>
                                                            <div style={{ height: '10rem', width: '100%', overflow: 'hidden' }}>
                                                                <img className='store-product-img' width='70%' src={`https://vyaparapi.emedha.in/images/${data.pPhoto}`} />
                                                            </div>
                                                            <div className='card-hover mt-5'>
                                                                <h1 className='store-product-name text-start text-danger ms-2'>Product: {data.pName}</h1>
                                                                <div className='store-product-content'>
                                                                    <p className='validity'>Validity: {data.validity}</p>
                                                                    <p className='store-product-price'>Price: {data.pPrice - data.discount / 100 * data.pPrice} <span className='discount-amount' >{data.pPrice}</span></p>
                                                                    <p className='store-product-discount'>Discount: <span style={{ color: 'green', fontSize: 16 }}> {data.discount}%</span></p>
                                                                    <div className='d-flex flex-row'>
                                                                        <Button className='buy-product-btn mb-3 mt-0 mt-2' variant="outline-primary" >Update</Button>
                                                                        <Button className='buy-product-btn mb-3 mt-0 mt-2' variant="outline-danger" style={{ marginLeft: 20 }} >Delete</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>}
                    </div>
                )
            }
        </>
    )
}

export default MyStore