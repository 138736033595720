
import React, { useEffect } from 'react';
import Pagination from '@mui/material/Pagination';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap'
import { BsSearch } from 'react-icons/bs'
import { IoMdArrowDropdown } from 'react-icons/io'
import { FaEdit, FaUserCircle } from 'react-icons/fa'
import { MdAddAPhoto, MdLocationOn } from 'react-icons/md'
import { GoVerified } from 'react-icons/go'
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css'
import './index.css'
import { useState } from 'react';
import ReferralCloseProductMoreDetails from '../ReferralCloseProductMoreDetails';
import axios from 'axios';
import NavbarEvyapar from '../NavbarEvyapar';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Box, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';




const ReferralClose = () => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [GstValidated, setGstValidated] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
    };


    const [lgShow, setLgShow] = useState()

    const [productShow, setProductShow] = useState(false);

    const [isOpenProduct, setOpenProduct] = useState(false)


    const openProductMoreDetails = () => {
        setOpenProduct(true)
    }


    const [isOpenTextare, setTextarea] = useState(false)

    const onSelectedText = () => {
        setTextarea(true)
    }

    // const getInitialState = () => {
    //     const remark = "Other";
    //     return remark;
    // };

    const [remark, setValue] = useState('');


    const onChangeInput = event => {
        setValue(event.target.value)
    }

    const [value, setMessage] = useState('');

    const onChangeValue = event => {
        setMessage(event.target.value)
    }

    const [referralCloseData, setReferralClose] = useState([])

    const ReferralCloseData = () => {
        const email = sessionStorage.getItem('email')

        axios.post('https://vyaparapi.emedha.in/ReferralClose/get', {
            email
        }).then((res) => {
            setReferralClose(res.data)
            console.log(res.data)
        })
    }


    useEffect(() => {
        ReferralCloseData()
    }, [])


    const [photo, setPhoto] = useState()
    const [productname, setProductName] = useState()
    const [price, setprice] = useState()
    const [discount, setDiscount] = useState()

    const [discountprice, setDiscountPrice] = useState()
    const [description, setDescription] = useState()
    const [location, setLocation] = useState()


    const viewProduct = (photo, productname, price, discount, description, location) => {
        setPhoto(photo)
        setProductName(productname)
        setprice(price)
        setDiscount(discount)
        const dis = (discount / 100) * price
        setDiscountPrice(price - dis)
        setDescription(description)
        setLocation(location)
        setProductShow(true)
    }








    const closeBusiness = () => {

        if ((value === '0' && remark !== "") || value === '1') {
            axios.post('https://vyaparapi.emedha.in/close-business/post', {
                value,
                remark
            }).then((res) => {
                alert(res.data)
                setProductShow(false)
                setLgShow(false)

            })
        } else {
            alert("can't be empty")
        }
    }



    return (
        <>
          
            <div className='referral-close-container'>
                <div className='container-fluid'>
                    <div className='row mt-5'>
                        <div className='col-lg-6 mx-auto'>
                            <h1 className='referral-in-heading'>Referral Close</h1>
                        </div>
                        <div className='col-lg-6 mx-auto'>
                            <div className="search-input-container mb-3">
                                <input
                                   
                                    type="search"
                                    className="search-input"
                                    placeholder="Search"
                               
                                />
                                <BsSearch className="search-icon" />
                            </div>
                        </div>


                    </div>
 
                    <div className='row'>
                        <div className='col-lg-12 mx-auto mb-4'>
                            <Paper>
                                <TableContainer sx={{ maxHeight: 600, cursor: 'default' }}>

                                    <Table style={{ width: '100%' }}>
                                        <TableHead sx={{ backgroundColor:'#02375a', borderRadius: '0.7rem' }}>

                                            <TableRow>

                                                <TableCell

                                                    align={"center"}
                                                    style={{ top: 57, minWidth: 30, fontWeight: 'bold',color:"white" }}
                                                >
                                                    S.No
                                                </TableCell>
                                                <TableCell

                                                    align={"center"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold',color:'white' }}
                                                >
                                                    Product
                                                </TableCell>
                                                <TableCell

                                                    align={"center"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold',color:'white' }}
                                                >
                                                    Referral Id
                                                </TableCell>
                                                <TableCell

                                                    align={"center"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold',color:'white' }}
                                                >
                                                    Price
                                                </TableCell>
                                                <TableCell

                                                    align={"center"}
                                                    style={{ top: 57, minWidth: 120, fontWeight: 'bold',color:'white'}}
                                                >
                                                    Customer Saler Names
                                                </TableCell>


                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {referralCloseData
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((data, index) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={data.code}>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 30 }}>{index + 1}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 120 }}><img className='referral-close-table-img' src={`https://vyaparapi.emedha.in/images/${data.productPhoto}`} /></TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 120, textDecoration: 'underline', cursor: 'pointer', textDecorationColor: 'black',fontWeight:'bold' }} onClick={() => viewProduct(data.productPhoto, data.productName, data.price, data.discount, data.discription, data.location)} >EV00{index+1}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 120 }}>{data.price}</TableCell>
                                                            <TableCell align={"center"}
                                                                style={{ top: 57, minWidth: 120 }}>{data.name}</TableCell>


                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>

                                    </Table>



                                </TableContainer>

                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 100]}
                                    component="div"
                                    count={referralCloseData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}

                                />


                            </Paper>
                        </div>
                    </div>

                </div>


            </div >



            <Modal
                size="lg"
                show={productShow}
                onHide={() => setProductShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered

            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Referral Close
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row mx-auto referral-close-in-product'>
                            <div className='col-lg-4 mx-auto'>
                                <img width='100%' className='pop-up-img' src={`https://vyaparapi.emedha.in/images/${photo}`} />

                            </div>
                            <div className='col-lg-6 mx-auto pop-up-referral-cont pt-3'>
                                <h1 className='product-typ' ><span className='mobile-types'>Product: </span>{productname}</h1>
                                <p style={{ fontWeight: 'bold' }}><span className='mobile-types'>Price:</span>{discountprice}<span className='discount-price'>{price}</span><span className='discount-percentage'>{discount} off</span></p>
                                <p className='product-description'><span className='mobile-types'>Description:</span>{description} </p>

                                <p><span className='mobile-typ' style={{marginTop: 5 }}><span className='mobile-types'>Location: </span></span> {location}</p>


                            </div>

                            <div className='col-lg-2 mx-auto mt-1 mb-1 text-center aceept-close-btn'>
                                <Button className='mt-0 mb-3' variant='outline-danger' style={{ width: 80, marginRight: 25 }} onClick={() => setLgShow(true)}>Close</Button>

                            </div>




                        </div>
                    </div>
                </Modal.Body>
            </Modal>




            <div className='d-flex flex-column justify-content-center align-items-center referr'>
                <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered

                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Referral Close
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className='form-referral-close-container'>

                            <input type='number' id='value' className='update-form-input3' placeholder='Enter Value' onChange={onChangeValue} />

                            {value === '0' &&
                                <div className='d-flex flex-column'>
                                    <h1 style={{ fontSize: 20, fontFamily: 'cursive' }}>Reasons:</h1>
                                    <select onChange={onChangeInput}>
                                        <option value="" disabled selected>Select Your Reason</option>
                                        <option value='Not Interest'>Not Interest</option>
                                        <option value='Price High'>Price High</option>
                                        <option value='Not Lifting Call'>Not Lifting Call</option>
                                        <option value='other'>Other</option>

                                    </select>


                                    {remark === 'other' && <textarea rows='5' cols='50' placeholder='type your reason' style={{ backgroundColor: 'white' }} />}
                                </div>
                            }

                            <div className='mt-3 mb-2'>
                                <Button variant='dark' style={{ width: 120, marginRight: 25, backgroundColor: 'rgb(13, 10, 70)' }} onClick={closeBusiness}>Submit</Button>

                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>



        </>
    )
}


export default ReferralClose